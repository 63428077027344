import * as React from 'react';
import { Box, Grid, Link, Typography, Container, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: '#',
  twitter: '#',
  instagram: '#',
};

const Footer = () => {
  return (
    <Box
      sx={{
        //bgcolor: 'background.paper',
        //color: 'text.secondary',
        bgcolor: 'black',
        color: 'black',
        py: 3,
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4" gutterBottom>
              <span style={{color:  `rgb(105, 35, 35)`}}>S</span>
              <span style={{color: `rgb(255, 255, 255)`}}>pring Film Studios</span>
              
            </Typography>
            {/* Add your logo component or image here */}
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              PRODUCT
            </Typography>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Features</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Integrations</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Pricing</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">FAQ</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              COMPANY
            </Typography>
            <Link href="#" color="rgb(255, 255, 255)" display="block">About Us</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Careers</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Privacy Policy</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Terms of Service</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Contact Us</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              OUR STORY
            </Typography>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Experience</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Films</Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block">Clients</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              SOCIAL MEDIA
            </Typography>
            <IconButton aria-label="Facebook" color="white" component="a" href={socialMediaLinks.facebook}>
              <FacebookIcon sx={{
                color: "white"
              }}/>
            </IconButton>
            <IconButton aria-label="Twitter" color="white" component="a" href={socialMediaLinks.twitter}>
              <TwitterIcon sx={{
                color: "white"
              }}/>
            </IconButton>
            <IconButton aria-label="Instagram" color="white" component="a" href={socialMediaLinks.instagram}>
              <InstagramIcon sx={{
                color: "white"
              }}/>
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="body2" color="white" align="center" sx={{ pt: 4 }}>
          © 2024 Xpring co. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;