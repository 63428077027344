import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Appbar from "./Appbar";
import Footer from "./Footer";
import BannerCard from "./Banner";
import ContentCard from "./Contentcard";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  background: `rgb(0, 0, 0)`,
  color: "black",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: `rgb(0, 0, 0)`,
  color: "black",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div
      // sx={{
      //   display: "flex",
      //   background: `rgb(0, 0, 0)`,
      //   color: "black",
      // }}
      style={{ width: "100%", padding: "0px" }}
    >
      <Appbar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        drawerWidth={drawerWidth}
      ></Appbar>
      <BannerCard />
      <div
        style={{ width: "100%", padding: "0px" }}
        // component="main"
        // sx={{
        //   flexGrow: 1,
        //   // p: 8,
        //   background: `rgb(0, 0, 0)`,
        //   color: "black",
        // }}
      >
        <DrawerHeader />
        <Typography
          //variant="h4"
          align="center"
          sx={{
            fontFamily: "Comic Sans MS",
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
            color: "white",
          }}
        >
          Welcome to Spring Film Studio...............
        </Typography>
        <ContentCard title="Our Experience" body="TBC" />
        <ContentCard title="Our Films" body="TBC" />
        <ContentCard title="Our Clients" body="TBC" />
        <ContentCard
          title="Contact Us"
          body={
            <div>
              <img
                onClick={() =>
                  (window.location.href = "https://xpring.co.uk/contact?type=0")
                }
                src="./contact.jpg"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </div>
          }
        />
        <br />
        <Footer />
      </div>
    </div>
  );
}
